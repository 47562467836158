import React, { useState, useEffect } from 'react';
import { checkSgDashboardProps, createSgDashboardPropsMapping } from '../common/SgDashboard';
import { Props } from './api';
import { widgetize, WidgetPropsMapping, useWidgetConfiguration } from '@sg-widgets/react-core';
import { useAsyncControl } from '../common/AsyncControl';
import { useEmptyStates, ErrorMsg } from '../common/EmptyStates';
import { Loading } from '../common/Loading';
import { UserActionRequiredError, RequestAccessError } from '../common/Errors';
import { useLastSeqNum } from '../common/useLastSeqNum';

export const ArclabText: React.FC<Props> = props => {
  const { inputData, logKey, query, theme, sgConnectEnvironment, widgetAttributes, widgetProperties } = props;
  checkSgDashboardProps(props, 'ArclabText');
  if (query === undefined) throw new Error(`ArclabText key[${logKey}] missing property[query]`);
  //
  const [text, setText] = useState<string | null>(null);
  const conf = useWidgetConfiguration();
  const { start: asyncStart, stop: asyncStop, cleanup } = useAsyncControl(logKey);
  const {
    start: emptyStatesStart,
    stop: emptyStatesStop,
    loading,
    errorMsg,
    userActionNeeded,
    param,
    requestAccessNeeded
  } = useEmptyStates();
  const { triggerEvent, userSelectionRef } = useLastSeqNum(props);
  useEffect(() => {
    async function createText(): Promise<void> {
      emptyStatesStart();
      const ctl = asyncStart();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { seqNum, ...inputs } = inputData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let textAny: any = null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let error: any = null;
      try {
        conf.debug(`createText() key[${logKey}] start`);
        textAny = await query({
          widgetConfiguration: conf,
          logKey,
          inputData: inputs,
          UserActionRequiredError,
          RequestAccessError,
          theme,
          sgConnectEnvironment,
          triggerEvent,
          userSelection: userSelectionRef.current,
          attributes: widgetAttributes,
          properties: widgetProperties
        });
        conf.debug(`createText() key[${logKey}] stop items[${textAny}]`);
      } catch (err) {
        conf.error(`createText() key[${logKey}] stop error[${err}]`);
        error = err;
      }
      if (asyncStop(ctl)) return;
      if (emptyStatesStop(error)) {
        setText(null);
        return;
      }
      //
      if (typeof textAny !== 'string') {
        throw new Error(`ArclabText createText() key[${logKey}] text[${textAny}] must be a string`);
      }
      const text = textAny as string;
      setText(text);
    }
    createText();
    return cleanup;
  }, [
    query,
    cleanup,
    conf,
    inputData,
    logKey,
    asyncStart,
    asyncStop,
    emptyStatesStart,
    emptyStatesStop,
    theme,
    sgConnectEnvironment,
    triggerEvent,
    userSelectionRef,
    widgetAttributes,
    widgetProperties
  ]);
  //
  if (errorMsg)
    return (
      <ErrorMsg
        msg={errorMsg}
        userActionNeeded={userActionNeeded}
        param={param}
        requestAccessNeeded={requestAccessNeeded}
      />
    );
  if (loading) return <Loading />;
  if (text === null) return null;
  return (
    // explantion for the use of margin auto: https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container
    <div
      className="d-flex align-items-center h-100 w-100"
      dangerouslySetInnerHTML={{
        __html: `<span class="text-primary" style="margin-top: auto; margin-bottom: auto;">${text}</span>`
      }}
    />
  );
};

const tagName = 'arclab-text';
if (window.customElements && !window.customElements.get(tagName)) {
  widgetize(tagName, ArclabText, {
    ...createSgDashboardPropsMapping('output-event'),
    query: WidgetPropsMapping.asObject()
  });
}
